import React, { useEffect, useRef, useState } from "react";
import Container from "../../../components/chat-channel/Container";
import { Link } from "gatsby";
import { useMedia } from "use-media";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import {
  AdHomePage_November,
  ISOSecurity_November,
} from "../../conversational-ai-platform";
import wlogo from "../../../assets/images/workativ-logo.png";
import { RequestForm } from "../../../components/form";
import Calendly from "react-calendly";

export const heroImage = require("../../../assets/img/home_page_assist/home_header_assist.png");
export const heroImageWider = require("../../../assets/img/home_page_assist/hero_img_paid_ad.png");
export const heroImageWiderPopUp = require("../../../assets/img/home_page_assist/hero_img_popup_paid_ad.png");
const clientLogo = require("../../../assets/img/feature/home/client_logo_mar24.svg");
const clientLogoMobile = require("../../../assets/img/feature/home/client_logo_mar24_mob.svg");
export const chatbotApps = require("../../../assets/img/feature/home/chatbots_apps.png");
const gabriel = require("../../../assets/img/feature/home/landing_client_1.png");
const arrowSpringed = require("../../../assets/img/home_page_assist/assitants_home_arrow_springed.png");
const arrowSpringedFliped = require("../../../assets/img/home_page_assist/spring_arrow_fliped.png");
const christina = require("../../../assets/img/feature/home/landing_client_2.png");
const goto = require("../../../assets/img/home_page_assist/goto_logo_paid_lan.svg");
const cognosante = require("../../../assets/img/home_page_assist/cog_logo_paid_lan.svg");
const quotes = require("../../../assets/images/testimonials/quote.png");
const fourInOne = require("../../../assets/img/home_page_assist/four_in_one.svg");
const tabOne = require("../../../assets/images/testimonials/landing_slider_1.png");
const tabTwo = require("../../../assets/images/testimonials/landing_slider_2.png");
const tabThree = require("../../../assets/images/testimonials/landing_slider_3.png");
const tabFour = require("../../../assets/images/testimonials/landing_slider_4.png");
const countOne = require("../../../assets/img/home_page_assist/count_one.svg");
const countTwo = require("../../../assets/img/home_page_assist/count_two.svg");
const countThree = require("../../../assets/img/home_page_assist/count_three.svg");
const countFour = require("../../../assets/img/home_page_assist/count_four.svg");
const countOneInactive = require("../../../assets/img/home_page_assist/count_one_inactive.svg");
const countTwoInactive = require("../../../assets/img/home_page_assist/count_two_inactive.svg");
const countThreeInactive = require("../../../assets/img/home_page_assist/count_three_inactive.svg");
const countFourInactive = require("../../../assets/img/home_page_assist/count_four_inactive.svg");
const tick = require("../../../assets/images/onboarding/onboarding_tick.svg");
const slack = require("../../../assets/images/features_images/features_slack.png");
const teams = require("../../../assets/images/features_images/features_teams.png");
const chat = require("../../../assets/images/features_images/features_chat.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Workativ",
  url: "https://workativ.com",
  description:
    " Workativ Assistant is easy to use 'AI + Automation' SaaS product for companies to build an intelligent Virtual Assistant/Chatbot with pre-built automations, to provide employee support, reduce Help Desk costs, and increase productivity. No coding required! ",
  sameAs: [
    "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    "https://twitter.com/workativ",
    "https://www.facebook.com/workativ",
  ],
  sourceOrganization: {
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      " Workativ offers an intelligent AI + Automation platform to build and deploy purpose-built Chatbot for workplace support. ",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
  },
};

export default function HomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Assistant: Gen AI Self-Service Automation Tool"
        description="Automate your Employee Support with Gen AI Chatbot Automation"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        homepagrUrl
        schema={ldSchema}
      />
      <div className="">
        <Container>
          <Header />
          <TopHeadSecPaidLanding
            heading={
              "Automate your Employee Support with Gen AI Chatbot Automation"
            }
            buttonText={"Book a demo"}
            heroImage={heroImage}
            onButtonClick={handleClickBookDemo}
          />
          <TabsSection
            tabContent={tabContent}
            onButtonClick={handleClickBookDemo}
          />
          <ChatbotsCategories
            heading={"Integrate chatbot with app workflows in seconds."}
            description={
              "Pick from over 1000+ pre-built automations on popular apps, and automate your employee support."
            }
            appsImage={chatbotApps}
          />
          <AdHomePage_November />
          <ClientTestimonials onButtonClick={handleClickBookDemo} />
          <BookDemoAndSignUp data={demoData} />
          <ISOSecurity_November noLink={true} />
          <Footer />
        </Container>
      </div>
    </>
  );
}
export function TopHeadSecPaidLanding(data, onButtonClick) {
  const { heading, buttonText, heroImage, textColorBlue } = data;
  const isSmall = useMedia({ maxWidth: "520px" });
  // const handleClick = () => {
  //   if (typeof onButtonClick === "function") {
  //     onButtonClick();
  //   }
  // };
  const handleClick = () => {
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const scrollAdjust = 100;
        window.scrollBy(0, -scrollAdjust);
      }, 1000);
    }
  };
  return (
    <section className="w-100 float-left head-section-landing">
      <div className="container">
        <div className="flex-container-landing">
          <div className="left-flex-item-landing">
            <h1 className="font-page-header mb-4">{heading}</h1>
            <button
              className="font-section-normal-text-medium button-landing"
              onClick={handleClick}
            >
              {buttonText}
            </button>
          </div>
          <div className="right-flex-item-landing">
            <img src={heroImage} alt={heading} />
          </div>
        </div>
        {textColorBlue ? (
          <h3
            className="font-section-normal-text-medium-customer-banner text-align-center"
            style={{ color: "#2C67F6" }}
          >
            Join leading enterprises that trust Workativ
          </h3>
        ) : (
          <h3 className="font-section-normal-text-medium-customer-banner text-align-center">
            Join hundreds of industry-leaders
          </h3>
        )}
        <img
          src={isSmall ? clientLogoMobile : clientLogo}
          alt="Client Logo"
          className={`${!isSmall ? "w-90 m-auto d-flex" : ""}`}
        />
      </div>
    </section>
  );
}
export function ChatbotsCategories(data) {
  const { heading, description, appsImage } = data;
  return (
    <section className="w-100 float-left apps-section-landing">
      <div className="container">
        <div className="chatbots-container-landing w-100 d-flex flex-column align-items-center">
          <h2 className="font-section-sub-header mb-2 text-align-center">
            {heading}
          </h2>
          <p className="font-section-normal-text-testimonials text-align-center mb-0">
            {description}
          </p>
          <img src={appsImage} />
        </div>
      </div>
    </section>
  );
}
export function ClientTestimonials(withoutButton) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const handleClick = () => {
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const scrollAdjust = 100;
        window.scrollBy(0, -scrollAdjust);
      }, 1000);
    }
  };
  return (
    <section className="w-100 float-left testimonials-section-landing">
      <div className="container">
        <h2 className="font-section-sub-header text-align-center">
          See how game-changing companies are automating
          <br /> employee support with Workativ
        </h2>
        <div className="flex-box-testi-lan">
          {withoutButton ? (
            <div className="left-box-testi-lan">
              <h2 className="font-section-medium-bigger">
                Why brands love{" "}
                <span style={{ color: "#2368F6" }}>Workativ?</span>
              </h2>
            </div>
          ) : (
            <div className="left-box-testi-lan">
              <h2 className="font-section-sub-header-big-landing mb-4">
                Why users love Workativ?
              </h2>
              <button
                className="font-section-normal-text-medium button-landing"
                onClick={handleClick}
              >
                Book a Demo
              </button>
            </div>
          )}

          <div className="right-box-testi-lan">
            {testimonialData.map((data) => (
              <div
                className="testi-box-lan"
                style={{
                  backgroundColor: data.isGreen ? "#C6FFDD" : "#FCDE94",
                }}
              >
                <img src={quotes} className="quotes__testi__lan" />
                <div className="testi-box-top">
                  <p
                    className={`custom__lineheight text-align-center color-black mt-3 mb-0 pb-0 ${
                      isSmall
                        ? "font-text-medium-goto-solution"
                        : "font-section-small-text-medium-apps "
                    }`}
                  >
                    {data.testimoniContent}
                  </p>
                </div>
                <div className="testi-box-bottom">
                  <img
                    src={data.clientImg}
                    className="client__image__testi__lan mb-3"
                  />
                  <h4
                    className="font-section-sub-header-smaller-bold-testimonials mb-1"
                    style={{
                      color: data.isGreen ? "#4F75D9" : "#F99002",
                    }}
                  >
                    {data.clientName}
                  </h4>
                  <h5 className="font-section-small-text mb-0">
                    {data.clientDesignation}
                  </h5>
                  <img
                    src={data.clientLogoImg}
                    className="client__logo__image__testi__lan mt-3"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
export const testimonialData = [
  {
    testimoniContent:
      "After researching 5 different chatbot solutions, we decided to go ahead with Workativ for its’ easy integration with systems that we use internally at GoTo.",
    clientImg: gabriel,
    clientName: "Gabriel Grecco",
    clientDesignation: "Global Manager - IT Service Desk",
    clientLogoImg: goto,
    isGreen: true,
  },
  {
    testimoniContent:
      "We engaged with several vendors. Through collaborative efforts and insights from various team members, we discovered Workativ—a frontrunner in employee support automation.",
    clientImg: christina,
    clientName: "Christeena Burks",
    clientDesignation: "Manager, HR Shared Services",
    clientLogoImg: cognosante,
  },
];
export function BookDemoAndSignUp({ data }) {
  const { title, description, listItems } = data;
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section
      className="w-100 float-left book-demo-sign-up-section-landing"
      id="targetSection"
    >
      <div className="container">
        <div className="flex-container-demo-signup-landing">
          <div className="left-item-demo-signup-lan">
            <h2
              className="font-section-sub-header mb-2"
              style={{
                backgroundColor: "#F7F71A",
                padding: "5px 10px 0px",
                width: "fit-content",
              }}
            >
              {title}
            </h2>
            <div className="demo-signup-list-box">
              <h3 className="font-section-normal-text-medium">{description}</h3>
              <ul className="w-100 mb-0 list-style-type-none pl-0">
                {listItems.map((item, index) => (
                  <div
                    className={`d-flex  gap-10 align-items-start ${
                      index === listItems.length - 1 ? "mb-0" : "mb-4 "
                    } `}
                  >
                    <img
                      src={tick}
                      style={{
                        position: isMobile ? "relative" : "relative",
                        top: isMobile ? "2px" : "1px",
                        width: isMobile ? "14px" : "18px",
                      }}
                    />
                    <li
                      key={index}
                      className={`font-section-small-text list__paid___lan`}
                    >
                      {item}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
            {isMobile ? null : (
              <img
                src={arrowSpringedFliped}
                className="springed__arrow__landing"
                alt="Arrow"
              />
            )}
          </div>
          <div className="right-item-demo-signup-lan">
            <iframe
              src="https://meetings.hubspot.com/prernit-dua/clone?embed=true"
              style={{ border: "none", height: "450px", width: "100%" }}
              scrolling="yes"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
export const demoData = {
  title: "Book a Demo",
  description: "Our demo covers:",
  listItems: [
    "How chatbot auto-resolves 80% of employee IT & HR queries in seconds ",
    "How to use App Workflows in chatbot for end-to-end workflow automation",
    "Integrate bot with multiple ITSM platforms, HR apps, and 80+ apps instantly ",
    "Knowledge AI - build your own ChatGPT for your Knowledge Base ",
    "AI-powered Shared Live Inbox for agents to provide live chat support ",
    "Learn cost savings from case studies",
  ],
};
export function TabsSection({ tabContent }) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabMobile, setActiveTabMobile] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleTabClickMobile = (index) => {
    setActiveTabMobile((prevActiveTabMobile) =>
      prevActiveTabMobile === index ? null : index
    );
  };

  const handleClick = () => {
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const scrollAdjust = 100;
        window.scrollBy(0, -scrollAdjust);
      }, 1000);
    }
  };

  return (
    <section className="w-100 float-left tabs__section__container__landing">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={fourInOne} className="four-in-one" alt="Four in One" />
          <h4 className="font-section-sub-header text-align-center">
            The most advanced <s className="strike-through-con">single</s>{" "}
            support automation platform
          </h4>
          <p className="font-section-normal-text-testimonials text-align-center">
            Maximize productivity with one central workspace that connects your
            bots, app workflows, knowledge base, and live inbox.
          </p>
        </div>
        {isSmall ? (
          <>
            <div>
              <div className="tabs__wrapper__landing">
                {tabContent.map((tab, index) => (
                  <>
                    <div
                      key={index}
                      className={`tab__items__button__icon ${
                        index === activeTabMobile
                          ? "active__tab__items__button__icon"
                          : ""
                      } ${
                        index === tab.length - 1 && index !== activeTabMobile
                          ? "mb-0"
                          : ""
                      }`}
                      onClick={() => handleTabClickMobile(index)}
                    >
                      <img
                        className="tab__icon__landing"
                        src={` ${
                          index === activeTabMobile
                            ? tab.countImg
                            : tab.countInactive
                        }`}
                        alt="Tab Icon"
                      />
                      <button
                        className={`tab__button__landing font-section-normal-text-medium-link ${
                          index === activeTabMobile
                            ? "active__tab___button"
                            : ""
                        }`}
                      >
                        {tab.title}
                        {tab.isSup ? (
                          <sup className="font-section-normal-text-medium-small">
                            + LLM
                          </sup>
                        ) : null}
                      </button>
                      {index === activeTabMobile ? (
                        <span>&#8595;</span>
                      ) : (
                        <span>&#8594;</span>
                      )}
                    </div>
                    {activeTabMobile === index && (
                      <div
                        className={`tabs__content__container__landing ${
                          index === tabContent.length - 1 ? "mb-0" : ""
                        }`}
                      >
                        <div className="tabs__text__content__landing">
                          <p className="tab__text__landing font-section-normal-text-testimonials line-height-18 mb-1">
                            {tabContent[activeTabMobile].content}
                          </p>
                          <button
                            className="tab__button__content__landing button-landing font-section-normal-text-medium"
                            onClick={handleClick}
                          >
                            {tabContent[activeTabMobile].buttonText}
                          </button>
                        </div>
                        <div className="tabs__image__container__landing">
                          <img
                            className="tab__image__landing"
                            src={tabContent[activeTabMobile].img}
                            alt="Image"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tabs__wrapper__landing">
              {tabContent.map((tab, index) => (
                <div
                  key={index}
                  className={`tab__items__button__icon ${
                    index === activeTab
                      ? "active__tab__items__button__icon"
                      : ""
                  } ${
                    index === 0
                      ? "pl-2"
                      : index === tab.length - 1
                      ? "pr-2"
                      : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  <img
                    className="tab__icon__landing"
                    src={` ${
                      index === activeTab ? tab.countImg : tab.countInactive
                    }`}
                    alt="Tab Icon"
                  />
                  <button
                    className={`tab__button__landing font-section-normal-text-medium-link ${
                      index === activeTab ? "active__tab___button" : ""
                    }`}
                  >
                    {tab.title}
                    {tab.isSup ? (
                      <sup className="font-section-normal-text-medium-small">
                        + LLM
                      </sup>
                    ) : null}
                  </button>
                </div>
              ))}
            </div>
            <div className="tabs__content__container__landing">
              <div className="tabs__text__content__landing">
                <img
                  src={arrowSpringed}
                  className="springed-arrow-assitant"
                  alt="Arrow"
                />
                <p className="tab__text__landing font-section-normal-text-testimonials mt-3 line-height-18">
                  {tabContent[activeTab].content}
                </p>
                <button
                  className="tab__button__content__landing button-landing font-section-normal-text-medium"
                  onClick={handleClick}
                >
                  {tabContent[activeTab].buttonText}
                </button>
              </div>
              <div className="tabs__image__container__landing">
                <img
                  className="tab__image__landing"
                  src={tabContent[activeTab].img}
                  alt="Image"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
export const tabContent = [
  {
    title: "Conversation AI Platform",
    content:
      "Build advanced chatbot with Generative AI capabilities to provide proactive, highly customized AI experience at scale.",
    buttonText: "Book a demo",
    img: tabOne,
    isSup: true,
    countImg: countOne,
    countInactive: countOneInactive,
  },
  {
    title: "Chatbot Automation",
    content:
      "Workativ assistant comes with easy-to-use no-code app workflow builder to help you build simple to complex app-based automations for chatbot in minutes.",
    buttonText: "Book a demo",
    img: tabTwo,
    countImg: countTwo,
    countInactive: countTwoInactive,
  },
  {
    title: "Knowledge AI GPT",
    content:
      "Knowldge AI uses the power of Generative AI and LLM to convert your knowledge base into crisp responses for user queries.",
    buttonText: "Book a demo",
    img: tabThree,
    countImg: countThree,
    countInactive: countThreeInactive,
  },
  {
    title: "Live Chat Inbox",
    content:
      "Deliver more resolutions faster with our AI powered shared inbox for your teams.",
    buttonText: "Book a demo",
    img: tabFour,
    countImg: countFour,
    countInactive: countFourInactive,
  },
];
export function Footer() {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left footer__landing">
      <div className="container">
        <div
          className={`d-flex justify-space-between align-items-center ${
            isMobile ? "flex-column" : ""
          }`}
        >
          <img src={wlogo} style={{ width: isMobile ? "25%" : "11%" }} />
          <h6
            className={`font-section-small-signup-form mb-0 ${
              isMobile ? "w-100 mt-2 text-align-center" : ""
            }`}
          >
            Workativ Copyrights © <span>{new Date().getFullYear()}</span>. All
            rights reserved.
          </h6>
        </div>
      </div>
    </section>
  );
}
export function Header() {
  const isMobile = useMedia({ maxWidth: "520px" });
  // const [isSticky, setIsSticky] = useState(false);
  // const handleScroll = () => {
  //   const offset = window.scrollY;
  //   if (offset > 0) {
  //     setIsSticky(true);
  //   } else {
  //     setIsSticky(false);
  //   }
  // };
  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  return (
    <section className="w-100 float-left header__landing sticky__header__landing">
      <div className="container">
        <img src={wlogo} className="header__logo__landing" />
      </div>
    </section>
  );
}
export const handleClickBookDemo = () => {
  const targetSection = document.getElementById("targetSection");
  if (targetSection) {
    targetSection.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      const scrollAdjust = 100;
      window.scrollBy(0, -scrollAdjust);
    }, 1000);
  }
};

//wide banner
export function TopSectionWideBanner(data, onButtonClick) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const {
    heading,
    heroImageWider,
    content,
    buttonText,
    headingMobile,
    contentMobile,
    isCustomerBanner,
  } = data;
  const handleClick = () => {
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const scrollAdjust = 100;
        window.scrollBy(0, -scrollAdjust);
      }, 1000);
    }
  };
  return (
    <section
      className={`width-100 float-left wide-head-section-landing ${
        isCustomerBanner ? "wide-head-section-landing-mobile" : ""
      }`}
    >
      <div className="container">
        <div className="flex-container-wide-landing">
          <div className="content-box-wide-landing">
            {isSmall ? (
              <>
                <h1 className="font-page-header">{headingMobile}</h1>
                <p className="font-section-normal-text-testimonials line-height-18 text-align-center">
                  {contentMobile}
                </p>
              </>
            ) : (
              <>
                <h1
                  className="font-page-header"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
                <p
                  className="font-section-normal-text-testimonials line-height-18 text-align-center"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </>
            )}
            <button
              className="font-section-normal-text-medium button-landing"
              onClick={handleClick}
            >
              {buttonText}
            </button>
          </div>
          <div className="image-box-wide-landing">
            <img src={heroImageWider} />
          </div>
          {isCustomerBanner ? (
            <>
              <h3
                className={`font-section-normal-text-medium-customer-banner text-align-center ${
                  isSmall ? "mt-5" : "mt-4"
                }`}
                style={{ color: "#2C67F6" }}
              >
                Join leading enterprises that trust Workativ
              </h3>
              <img
                src={isSmall ? clientLogoMobile : clientLogo}
                alt="Client Logo"
                className={`${!isSmall ? "w-90 m-auto d-flex" : ""}`}
              />
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
}
export function BookDemoCalendly(data, withUtmParameter) {
  const isSmall = useMedia({ maxWidth: "520px" });
  React.useEffect(() => {
    const script = document.createElement("script");
    if (document) {
      script.type = "text/javascript";
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      if (document) {
        document.body.removeChild(script);
      }
    };
  }, []);
  const { heading, subHeading, utmSource, utmCampaign } = data;
  const calendlyWidgetStyles = {
    minWidth: "320px",
    height: "700px",
  };
  const formatDate = (date) => {
    var d = new Date(date);
    d.setDate(d.getDate() + 1);

    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const baseUrl = "https://calendly.com/workativ/book-workativ-demo";
  const dateParam = `date=${formatDate(new Date())}`;
  const utmParams = `utm_source=${utmSource}&utm_campaign=${utmCampaign}`;
  return (
    <section
      className="book-demo-calendly-paid-lan width-100 float-left"
      id="targetSection"
    >
      <div className="container">
        <div className="book-demo-box-calendly">
          <h2 className="font-section-sub-header-bold text-align-center">
            {heading}
          </h2>
          <h3 className="font-section-normal-text-medium color-sky text-align-center">
            {subHeading}
          </h3>
        </div>
        {isSmall ? (
          <div
            className="calendly-inline-widget"
            data-url={baseUrl}
            style={calendlyWidgetStyles}
          ></div>
        ) : withUtmParameter ? (
          <div
            className="calendly-inline-widget"
            data-url={`${baseUrl}?${utmParams}&${dateParam}`}
            style={calendlyWidgetStyles} 
          ></div>
        ) : (
          <div
            className="calendly-inline-widget"
            data-url={`${baseUrl}?${dateParam}`}
            style={calendlyWidgetStyles}
          ></div>
        )}
      </div>
    </section>
  );
}

//with form
export function TopSectionWideBannerWithForm(data, onButtonClick) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const {
    heading,
    heroImageWider,
    content,
    headingMobile,
    contentMobile,
  } = data;
  const handleClick = () => {
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const scrollAdjust = 100;
        window.scrollBy(0, -scrollAdjust);
      }, 1000);
    }
  };
  return (
    <section className="width-100 float-left wide-head-section-landing">
      <div className="container">
        <div className="flex-container-wide-landing">
          <div className="content-box-wide-landing">
            {isSmall ? (
              <>
                <h1 className="font-page-header">{headingMobile}</h1>
                <p className="font-section-normal-text-testimonials line-height-18 text-align-center">
                  {contentMobile}
                </p>
              </>
            ) : (
              <>
                <h1
                  className="font-page-header"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
                <p
                  className="font-section-normal-text-testimonials line-height-18 text-align-center"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </>
            )}

            <RequestForm
              isFooterForm={false}
              contentHidden={true}
              product={"paidad"}
              additionalClassForm={"header__form__paid__ad"}
              additionalClassPaid={"header__cointainer__form__paid__ad"}
            />
            <span className="font-section-small-signup-form-medium limited__spot__text">
              Limited spots
            </span>
          </div>
          <div className="image-box-wide-landing">
            <img src={heroImageWider} />
          </div>
        </div>
      </div>
    </section>
  );
}
export function ClientTestimonialsWithForm() {
  const isSmall = useMedia({ maxWidth: "520px" });
  const handleClick = () => {
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const scrollAdjust = 100;
        window.scrollBy(0, -scrollAdjust);
      }, 1000);
    }
  };
  return (
    <section className="w-100 float-left testimonials-section-landing">
      <div className="container">
        <h2 className="font-section-sub-header text-align-center">
          See how game-changing companies are automating
          <br /> employee support with Workativ
        </h2>
        <div className="flex-box-testi-lan">
          <div className="left-box-testi-lan">
            <h2 className="font-section-sub-header-big-landing mb-4">
              Why users love Workativ?
            </h2>
            <RequestForm
              isFooterForm={false}
              contentHidden={true}
              product={"paidad"}
              additionalClassForm={"form__paid__ad"}
              additionalClassPaid={"container__form__paid__ad"}
            />
            <span className="font-section-small-signup-form-medium limited__spot__text__testimonials">
              Limited spots
            </span>
          </div>

          <div className="right-box-testi-lan">
            {testimonialData.map((data) => (
              <div
                className="testi-box-lan"
                style={{
                  backgroundColor: data.isGreen ? "#C6FFDD" : "#FCDE94",
                }}
              >
                <img src={quotes} className="quotes__testi__lan" />
                <div className="testi-box-top">
                  <p
                    className={`custom__lineheight text-align-center color-black mt-3 mb-0 pb-0 ${
                      isSmall
                        ? "font-text-medium-goto-solution"
                        : "font-section-small-text-medium-apps "
                    }`}
                  >
                    {data.testimoniContent}
                  </p>
                </div>
                <div className="testi-box-bottom">
                  <img
                    src={data.clientImg}
                    className="client__image__testi__lan mb-3"
                  />
                  <h4
                    className="font-section-sub-header-smaller-bold-testimonials mb-1"
                    style={{
                      color: data.isGreen ? "#4F75D9" : "#F99002",
                    }}
                  >
                    {data.clientName}
                  </h4>
                  <h5 className="font-section-small-text mb-0">
                    {data.clientDesignation}
                  </h5>
                  <img
                    src={data.clientLogoImg}
                    className="client__logo__image__testi__lan mt-3"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
export function BookDemoAndSignUpWithForm({ data }) {
  const { title, description, listItems } = data;
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section
      className="w-100 float-left book-demo-sign-up-section-landing"
      id="targetSection"
    >
      <div className="container">
        <div className="flex-container-demo-signup-landing flex-container-demo-signup-landing-with-form">
          <div className="left-item-demo-signup-lan">
            <h2
              className="font-section-sub-header mb-2"
              style={{
                backgroundColor: "#F7F71A",
                padding: "5px 10px 0px",
                width: "fit-content",
              }}
            >
              {title}
            </h2>
            <div className="demo-signup-list-box">
              <h3 className="font-section-normal-text-medium mb-3">
                {description}
              </h3>
              <ul className="w-100 mb-0 list-style-type-none pl-0">
                {listItems.map((item, index) => (
                  <div
                    className={`d-flex  gap-10 align-items-start ${
                      index === listItems.length - 1 ? "mb-0" : "mb-4 "
                    } `}
                  >
                    <img src={tick} className="list__items__paid__tick" />
                    <li
                      key={index}
                      className={`font-section-small-text list__paid___lan`}
                    >
                      {item}
                    </li>

                    {index === listItems.length - 1 ? (
                      <div className="d-flex paid__ad__book__demo__integrate__icons">
                        <img src={slack} alt="slack" />
                        <img src={teams} alt="teams" />
                        <img src={chat} alt="chatbot" />
                      </div>
                    ) : null}
                  </div>
                ))}
              </ul>
            </div>
            {isMobile ? null : (
              <img
                src={arrowSpringedFliped}
                className="springed__arrow__landing"
                alt="Arrow"
              />
            )}
          </div>
          <div className="right-item-demo-signup-lan-form">
            <RequestForm
              isFooterForm={false}
              contentHidden={true}
              product={"paidad"}
              additionalClassForm={"form__paid__ad"}
              additionalClassPaid={"container__form__paid__ad"}
            />
            <span className="font-section-small-signup-form-medium limited__spot__text__bookdemo">
              Limited spots
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
export const demoDataWithForm = {
  title: "Register for FREE Pilot",
  description: "Everything you need for an amazing bot",
  listItems: [
    "Easy Setup & On-boarding Included",
    "Pre-built Chatbots for quick start",
    "Automate FAQs with Knowledge AI GPT",
    "Integrate bot with 1000+ App workflows",
    "AI-powered Shared Live Chat Inbox for Agents",
    "Chatbot Templates & App Marketplace",
    "Multi-channel Support",
  ],
};
export function TabsSectionWithForm({ tabContent, marginBottom }) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabMobile, setActiveTabMobile] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleTabClickMobile = (index) => {
    setActiveTabMobile((prevActiveTabMobile) =>
      prevActiveTabMobile === index ? null : index
    );
  };

  const handleClick = () => {
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const scrollAdjust = 100;
        window.scrollBy(0, -scrollAdjust);
      }, 1000);
    }
  };

  return (
    <section
      className={`w-100 float-left tabs__section__container__landing ${
        marginBottom ? marginBottom : ""
      }`}
    >
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={fourInOne} className="four-in-one" alt="Four in One" />
          <h4 className="font-section-sub-header text-align-center">
            The most advanced <s className="strike-through-con">single</s>{" "}
            support automation platform
          </h4>
          <p className="font-section-normal-text-testimonials text-align-center">
            Maximize productivity with one central workspace that connects your
            bots, app workflows, knowledge base, and live inbox.
          </p>
        </div>
        {isSmall ? (
          <>
            <div>
              <div className="tabs__wrapper__landing">
                {tabContent.map((tab, index) => (
                  <>
                    <div
                      key={index}
                      className={`tab__items__button__icon ${
                        index === activeTabMobile
                          ? "active__tab__items__button__icon"
                          : ""
                      } ${
                        index === tab.length - 1 && index !== activeTabMobile
                          ? "mb-0"
                          : ""
                      }`}
                      onClick={() => handleTabClickMobile(index)}
                    >
                      <img
                        className="tab__icon__landing"
                        src={` ${
                          index === activeTabMobile
                            ? tab.countImg
                            : tab.countInactive
                        }`}
                        alt="Tab Icon"
                      />
                      <button
                        className={`tab__button__landing font-section-normal-text-medium-link ${
                          index === activeTabMobile
                            ? "active__tab___button"
                            : ""
                        }`}
                      >
                        {tab.title}
                        {tab.isSup ? (
                          <sup className="font-section-normal-text-medium-small">
                            + LLM
                          </sup>
                        ) : null}
                      </button>
                      {index === activeTabMobile ? (
                        <span>&#8595;</span>
                      ) : (
                        <span>&#8594;</span>
                      )}
                    </div>
                    {activeTabMobile === index && (
                      <div
                        className={`tabs__content__container__landing ${
                          index === tabContent.length - 1 ? "mb-0" : ""
                        }`}
                      >
                        <div className="tabs__text__content__landing">
                          <p className="tab__text__landing font-section-normal-text-testimonials line-height-18 mb-1">
                            {tabContent[activeTabMobile].content}
                          </p>
                          <RequestForm
                            isFooterForm={false}
                            contentHidden={true}
                            product={"paidad"}
                            additionalClassForm={"form__paid__ad"}
                            additionalClassPaid={"container__form__paid__ad"}
                          />
                          <span className="font-section-small-signup-form-medium limited__spot__text__tabs">
                            Limited spots
                          </span>
                        </div>
                        <div className="tabs__image__container__landing">
                          <img
                            className="tab__image__landing"
                            src={tabContent[activeTabMobile].img}
                            alt="Image"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tabs__wrapper__landing">
              {tabContent.map((tab, index) => (
                <div
                  key={index}
                  className={`tab__items__button__icon ${
                    index === activeTab
                      ? "active__tab__items__button__icon"
                      : ""
                  } ${
                    index === 0
                      ? "pl-2"
                      : index === tab.length - 1
                      ? "pr-2"
                      : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  <img
                    className="tab__icon__landing"
                    src={` ${
                      index === activeTab ? tab.countImg : tab.countInactive
                    }`}
                    alt="Tab Icon"
                  />
                  <button
                    className={`tab__button__landing font-section-normal-text-medium-link ${
                      index === activeTab ? "active__tab___button" : ""
                    }`}
                  >
                    {tab.title}
                    {tab.isSup ? (
                      <sup className="font-section-normal-text-medium-small">
                        + LLM
                      </sup>
                    ) : null}
                  </button>
                </div>
              ))}
            </div>
            <div className="tabs__content__container__landing">
              <div className="tabs__text__content__landing">
                <img
                  src={arrowSpringed}
                  className="springed-arrow-assitant"
                  alt="Arrow"
                />
                <p className="tab__text__landing font-section-normal-text-testimonials mt-3 line-height-18">
                  {tabContent[activeTab].content}
                </p>
                <RequestForm
                  isFooterForm={false}
                  contentHidden={true}
                  product={"paidad"}
                  additionalClassForm={"form__paid__ad"}
                  additionalClassPaid={"container__form__paid__ad"}
                />
                <span className="font-section-small-signup-form-medium limited__spot__text__tabs">
                  Limited spots
                </span>
              </div>
              <div className="tabs__image__container__landing">
                <img
                  className="tab__image__landing"
                  src={tabContent[activeTab].img}
                  alt="Image"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

//with popup
export function TopSectionWideBannerWithPopUp(data, onButtonClick) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const {
    heading,
    heroImageWider,
    content,
    buttonText,
    headingMobile,
    contentMobile,
    isCustomerBanner,
  } = data;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/workativ/book-workativ-demo",
      });
    }
  };
  return (
    <section
      className={`width-100 float-left wide-head-section-landing ${
        isCustomerBanner ? "wide-head-section-landing-mobile" : ""
      }`}
    >
      <div className="container">
        <div className="flex-container-wide-landing">
          <div className="content-box-wide-landing">
            {isSmall ? (
              <>
                <h1 className="font-page-header">{headingMobile}</h1>
                <p className="font-section-normal-text-testimonials line-height-18 text-align-center">
                  {contentMobile}
                </p>
              </>
            ) : (
              <>
                <h1
                  className="font-page-header"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
                <p
                  className="font-section-normal-text-testimonials line-height-18 text-align-center"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </>
            )}
            <button
              className="font-section-normal-text-medium button-landing"
              onClick={openCalendlyPopup}
            >
              {buttonText}
            </button>
          </div>
          <div className="image-box-wide-landing d-flex justify-content-center">
            <img
              src={heroImageWider}
              className={`${
                isSmall ? "w-100" : "w-90"
              } hero__wider__image__popup`}
            />
          </div>
          {isCustomerBanner ? (
            <>
              <h3
                className={`font-section-normal-text-medium-customer-banner text-align-center ${
                  isSmall ? "mt-5" : "mt-4"
                }`}
                style={{ color: "#2C67F6" }}
              >
                Join leading enterprises that trust Workativ
              </h3>
              <img
                src={isSmall ? clientLogoMobile : clientLogo}
                alt="Client Logo"
                className={`${!isSmall ? "w-90 m-auto d-flex" : ""}`}
              />
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
}
export function TabsSectionWithPopUp({ tabContent }) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabMobile, setActiveTabMobile] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleTabClickMobile = (index) => {
    setActiveTabMobile((prevActiveTabMobile) =>
      prevActiveTabMobile === index ? null : index
    );
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/workativ/book-workativ-demo",
      });
    }
  };

  return (
    <section className="w-100 float-left tabs__section__container__landing">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={fourInOne} className="four-in-one" alt="Four in One" />
          <h4 className="font-section-sub-header text-align-center">
            The most advanced <s className="strike-through-con">single</s>{" "}
            support automation platform
          </h4>
          <p className="font-section-normal-text-testimonials text-align-center">
            Maximize productivity with one central workspace that connects your
            bots, app workflows, knowledge base, and live inbox.
          </p>
        </div>
        {isSmall ? (
          <>
            <div>
              <div className="tabs__wrapper__landing">
                {tabContent.map((tab, index) => (
                  <>
                    <div
                      key={index}
                      className={`tab__items__button__icon ${
                        index === activeTabMobile
                          ? "active__tab__items__button__icon"
                          : ""
                      } ${
                        index === tab.length - 1 && index !== activeTabMobile
                          ? "mb-0"
                          : ""
                      }`}
                      onClick={() => handleTabClickMobile(index)}
                    >
                      <img
                        className="tab__icon__landing"
                        src={` ${
                          index === activeTabMobile
                            ? tab.countImg
                            : tab.countInactive
                        }`}
                        alt="Tab Icon"
                      />
                      <button
                        className={`tab__button__landing font-section-normal-text-medium-link ${
                          index === activeTabMobile
                            ? "active__tab___button"
                            : ""
                        }`}
                      >
                        {tab.title}
                        {tab.isSup ? (
                          <sup className="font-section-normal-text-medium-small">
                            + LLM
                          </sup>
                        ) : null}
                      </button>
                      {index === activeTabMobile ? (
                        <span>&#8595;</span>
                      ) : (
                        <span>&#8594;</span>
                      )}
                    </div>
                    {activeTabMobile === index && (
                      <div
                        className={`tabs__content__container__landing ${
                          index === tabContent.length - 1 ? "mb-0" : ""
                        }`}
                      >
                        <div className="tabs__text__content__landing">
                          <p className="tab__text__landing font-section-normal-text-testimonials line-height-18 mb-1">
                            {tabContent[activeTabMobile].content}
                          </p>
                          <button
                            className="tab__button__content__landing button-landing font-section-normal-text-medium"
                            onClick={openCalendlyPopup}
                          >
                            {tabContent[activeTabMobile].buttonText}
                          </button>
                        </div>
                        <div className="tabs__image__container__landing">
                          <img
                            className="tab__image__landing"
                            src={tabContent[activeTabMobile].img}
                            alt="Image"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tabs__wrapper__landing">
              {tabContent.map((tab, index) => (
                <div
                  key={index}
                  className={`tab__items__button__icon ${
                    index === activeTab
                      ? "active__tab__items__button__icon"
                      : ""
                  } ${
                    index === 0
                      ? "pl-2"
                      : index === tab.length - 1
                      ? "pr-2"
                      : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  <img
                    className="tab__icon__landing"
                    src={` ${
                      index === activeTab ? tab.countImg : tab.countInactive
                    }`}
                    alt="Tab Icon"
                  />
                  <button
                    className={`tab__button__landing font-section-normal-text-medium-link ${
                      index === activeTab ? "active__tab___button" : ""
                    }`}
                  >
                    {tab.title}
                    {tab.isSup ? (
                      <sup className="font-section-normal-text-medium-small">
                        + LLM
                      </sup>
                    ) : null}
                  </button>
                </div>
              ))}
            </div>
            <div className="tabs__content__container__landing">
              <div className="tabs__text__content__landing">
                <img
                  src={arrowSpringed}
                  className="springed-arrow-assitant"
                  alt="Arrow"
                />
                <p className="tab__text__landing font-section-normal-text-testimonials mt-3 line-height-18">
                  {tabContent[activeTab].content}
                </p>
                <button
                  className="tab__button__content__landing button-landing font-section-normal-text-medium"
                  onClick={openCalendlyPopup}
                >
                  {tabContent[activeTab].buttonText}
                </button>
              </div>
              <div className="tabs__image__container__landing">
                <img
                  className="tab__image__landing"
                  src={tabContent[activeTab].img}
                  alt="Image"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
